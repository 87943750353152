
.head-alert{
  &{
    top: 0;
    padding-left: 80px;
    text-align: center;
    width: 100%;
    background: #ffae0a;
    color: #fff;
    padding: 10px;
    font-size: 15px;
  }
  a{
    color: #fff;
  }
}

@media screen and (max-width: 1575px) {
  .sidebar .settings .dropdown .dropdown-menu.show{
    margin-left: 7px !important;
  }
}

@media screen and (max-width: 1446px) {
  .sidebar .help .dropdown .dropdown-menu.hpm.show {
    margin-left: 72px !important;
  }
}

@media screen and (max-width: 1515px) {
  .sidebar .notifications .dropdown.ntf .dropdown-menu.show {
    margin-left: 132px !important;
  }
}

.sidebar{
  &{
    height: 50px;
    width: 100%;
    background-color: #11103b!important;
    position: fixed;
    top: 0;
    z-index: 1;
  }
  .btn:active:focus{
    outline: none;
  }

  .dropdown-menu.dropdown-caret-right:after, .dropdown-menu.dropdown-caret-right:before {
    left: unset;
    right: 30px;
  }

  .settings .dropdown-menu:after, .settings .dropdown-menu:before {
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    content: "";
    display: inline-block;
    left: 210px;
    position: absolute;
  }

  .dropdown-menu:after, .dropdown-menu:before {
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    content: "";
    display: inline-block;
    left: 10px;
    position: absolute;
  }
  .dropdown-menu:before {
    border-bottom: 17px solid #ffffff;
    top: -14px;
  }
  a{
    cursor: pointer;
  }

.main-nav{
  &{
    float: left;
    margin-left: 30%;
  }
  a{
    &{
      padding: 15px;
      border-bottom: none;
      font-size: 16px;
    }
    &:hover{
      opacity: 1;
    }
     &.active{
       padding: 14px;
       border-bottom: 5px solid #007bff;
    }
    &.active:hover{
      opacity: 1;
    }
  }

  .beta{
    position: absolute;
    background: #fff;
    color: #11113b;
    padding: 2px 5px;
    font-size: 13px;
    border-radius: 4px;
    margin-left: 11px;
  }
}
.notifications{
  .dropdown.ntf{
    &{
      margin-left: -185px!important;
    }
    .dropdown-toggle{
    }
    .dropdown-menu{
      &:before{
        left: 177px;
      }
      &{
        width: 350px;
        margin-left: -178px !important;
      }
      img{
        max-width: 85%;
       padding: 30px;
      }
      ul{
        &{
          overflow: auto;
          max-height: 550px;
        }
      }
      .item{
        &{
          padding: 15px;
          border-bottom: 1px solid #f5f2f2;
        }
        p {
          margin: 0;
        }
        .close{
          float: right!important;
          margin-top: -5px;
          margin-right: 0px;
        }
        .btn{
          margin-top: 12px;
          text-decoration: none;
          background-color: #f4f6f9 !important;
        }
        .btn:hover{
          background-color: #e4e7ea !important;
        }
        .txt{
          a{
            padding: 0;
            float: none;
          }
        }
      }
    }
  }
  .notificationstick{
    &{
      font-size: 14px;
      position: absolute;
      background: #ff1d51;
      width: 22px;
      height: 22px;
      margin-left: -28px;
      margin-top: -9px;
      border-radius: 50%;
    }
  }
}

.help{
  .dropdown{
    &{
      margin-left: -121px!important;
    }
    .dropdown-toggle{
      &{
        float: left;
        background-color: transparent!important;
        border: none;
        font-size: 22px!important;
      }
    }
    .dropdown-menu.hpm{
      &{
        margin-left: -137px!important;
      }
      &:before{
        left: 138px;
      }
    }
  }
  .statusok{
    &{
      background: #1ac797;
      width: 10px;
      height: 10px;
      float: right;
      border-radius: 50%;
      margin: 6px;
    }
  }
}
  .settings{
    &{

    }
    .dropdown{
      &{
      margin-left: -55px;
      }
      button{
        height: 40px;
        width: 40px;
        font-size: 19px!important;
        border-radius: 50%;
        background-color: #3d46fb!important;
        color: #fff;
      }
      .dropdown-menu{
        &{
          width: 250px;
          float: left;
          margin-top: 25px;
          min-width: 12rem;
          padding: 10px 0;
          margin: .85rem 0 0;
          color: #6f7588;
          text-align: left;
          list-style: none;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #e0e5e9;
          border-radius: 10px;
          box-shadow: 0 15px 30px 0 #d6d6d6a8;
          animation-name: fadeIn;
          margin-left: -207px !important;
          margin-top: 7px!important;
        }
        li{
          &{
            border: none;
          }

          a{
            &{
              color: #6f7588;
              padding: 15px 20px;
              font-size: 16px;
            }
            &:hover{
              color: #16181b;
              text-decoration: none;
              background-color: #f4f6f9!important;
            }
          }
          .out{
            color: #f76b8f!important;
          }
        }
      }
    }
  }
  //background-color: #18174e;

  .dropdown{
    &{
      float: left;
      position: fixed;
      color: #18174e;
      margin-left: 195px;
      margin-top: 8px;
    }
    .currentProject{
      &{
        float: left;
        background: #000;
        padding: 5px 10px;
        border-radius: 21px;
        margin-right: 10px;
      }
      img{
        height: 25px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .projectLogo{
        width: 10px;
        height: 10px;
        float: left;
        margin: 6px 6px 0;
        border-radius: 2px;
      }
    }
    .app{
      &{
        float: left;
        padding-right: 10px;
        margin-top: 6px;
      }
    }
    button{
      &{
        background-color: #ffffff36;
        border: 1px solid #fff6;
        color: #ffffffc2;
        font-size: 14px!important;
        padding: 4px 6px!important;
      }
    }
    .dropdown-menu{
      &{
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e0e5e9;
        border-radius: 10px;
        box-shadow: 0 15px 30px 0 #d6d6d6a8;
        width: 300px;
        margin-top: 7px !important;
      }
    }
    ul{
      &{
        float: left;
        width: 100%;
      }
      li{
        float: left;
        width: 100%;
        border-bottom: 1px solid #0000001f;
      }
      .projectLogo{
        width: 20px;
        height: 20px;
        float: left;
        margin: 4px 6px;
        border-radius: 2px;
      }
    }
    .name, .desc{
      float:left;
      width: 70%;
      margin-top: 3px;
    }
    .desc{
      margin-top: 8px;
      font-size: 12px;
      font-weight: normal;
    }

    a{
      &{
        color: #18174e;
        margin-bottom: 0;
        padding: 10px;
        width: 100%;
        margin-top: 0;
      }
      &:hover{
        opacity: 0.9;
        color: #18174e!important;
      }
      &.active{
        border-bottom: 0px;
        color: #18174e;
        border-left: 4px solid #007bff;
      }
    }
  }
  .ct{
    width: 1170px;
    margin: 0 auto;
  }
  .logo{
    &{
      float: left;
      font-size: 17px;
      color: #ffffff;
      font-weight: bold;
      padding: 9px 10px;
    }
    &.template{
      background: linear-gradient(262deg,#ff7854,#fd267d);
    }
    img{
      width: 135px;
    }
    .avatar{
      width: 25px;
      height: 25px;
      margin-left: 20px;
      margin-top: 20px;
      border: 2px solid #fff;
      border-radius: 50%;
      background-size: cover;
    }
  }
  .user{
    &{
      position: absolute;
      bottom: 0;
      padding-bottom: 15px;
      width: 100%;
    }
    .avatar{
      width: 40px;
      height: 40px;
      margin: 0 auto;
      border-radius: 50%;
      background-position: center;
    }
  }
  ul{
    &{
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 2;
      float: right;
    }
    li{
      &{
        margin-bottom: 9px;
        margin: 0 auto;
        float:left;
        list-style: none;
      }
      i{
        float: left;
        margin-right: 15px;
      }
      a{
        &{
          color: #ffffffa8;
          font-size: 15px;
          margin-top: 4px;
          padding: 13px;
          text-decoration: none;
          font-weight: 500;
          float: left;
        }
        &:hover{
          color: #fff!important;
        }
        &.active{
          &{
            color: #fff;
            background: none;
            border-bottom: 5px solid #007bff;
            border-left: none;
            padding-bottom: 12px;
            opacity: 1;
          }
          i{
            opacity: 1;
          }
        }
        i{
          margin-top: 3px;
        }
        &:hover{
          &{
            color: #007bff;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.search-modal{
  &{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffffcc;
    z-index: 999;
  }
}
